export const setSelection = (element) => {
  if (document.body.createTextRange) {
    // ms
    const range = document.body.createTextRange();
    range.moveToElementText(element);
    range.select();
  } else if (window.getSelection) {
    // moz, opera, webkit
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const clearSelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE
    document.selection.empty();
  }
};

export function getHostNameFromUrl(urlStr) {
  try {
    const url = new URL(urlStr);
    return url.hostname;
  } catch (e) {
    return urlStr;
  }
}

export const delay = (ms = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const urlWithoutProtocol = (url) => url.replace(/http:\/\/|https:\/\//, '');

export const getQueryFromParams = (params) => {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== undefined && value !== '')
  );

  const queryParams = new URLSearchParams(filteredParams);
  return queryParams.size ? `?${queryParams.toString()}` : '';
};

export const getParamsFromQuery = (query) => {
  const params = new URLSearchParams(query || window.location.search);
  return Object.fromEntries(params.entries());
};

export const queryToUrl = (queryString, merge, history = true) => {
  if (merge) {
    const currentParams = getParamsFromQuery();
    const newParams = getParamsFromQuery(queryString);
    const params = {
      ...currentParams,
      ...newParams,
    };
    queryString = getQueryFromParams(params);
  }

  if (history) {
    window.history.pushState('', '', window.location.pathname + queryString);
  }
};

export const deepMerge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) {
      if (target[key] === undefined) {
        target[key] = {};
      }
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);

  return target;
};

/**
 * Gets value from browser's local storage by key and try to parse it as a JSON. If key is not in
 * the local storage or value is not a valid JSON, `undefined` will be returned.
 *
 * @param {string} key Key name to get from the local storage.
 * @param {boolean} [remove=false] If value should be delete from the local storage after usage.
 *   Default is `false`
 * @returns {any}
 */
export const parseFromLocalStorage = (key, remove = false) => {
  let result;
  try {
    result = JSON.parse(window.localStorage.getItem(key));
  } finally {
    if (remove) {
      window.localStorage.removeItem(key);
    }
  }
  return result;
};

export const hasOwnProperty = (object, key) => Object.prototype.hasOwnProperty.call(object, key);

export const objectHasValue = (object, value) => Object.values(object).includes(value);

export const toDashCase = (str) => str.toLowerCase().split(/\s+/).join('-');

export const findObjectProperty = (object, property) => {
  let result;

  for (const key of Object.keys(object)) {
    if (key === property) {
      result = object[key];
      break;
    } else if (object[key] instanceof Object) {
      const recursiveResult = findObjectProperty(object[key], property);

      if (recursiveResult) {
        result = recursiveResult;
        break;
      }
    }
  }

  return result;
};

export const shuffledArray = (array = []) => {
  // fisher-yates shuffle
  const shuffled = [...array];

  for (let currentIndex = array.length, randomIndex; currentIndex; ) {
    randomIndex = (Math.random() * currentIndex--) | 0; // eslint-disable-line
    [shuffled[currentIndex], shuffled[randomIndex]] = [
      shuffled[randomIndex],
      shuffled[currentIndex],
    ];
  }

  return shuffled;
};

const devicePixelRatio = window.devicePixelRatio ?? 1;

/**
 * Converts CSS dimension size to real image's dimension size according to window.devicePixelRatio.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
 *
 * @param {number} size
 * @returns {number}
 */
export const convertCssToPixels = (size) => {
  return Math.floor(size * devicePixelRatio);
};

const quality = window.devicePixelRatio > 1 ? 'lightest' : 'normal';

/**
 * Detect UC quality parameter based on window.devicePixelRatio.
 * https://uploadcare.com/docs/transformations/image/compression/#operation-quality
 *
 * @returns {string}
 */
export const qualitySmart = () => {
  return quality;
};
